<template>
    <lassoLayout>
        <div class="page-password-changed full_page">
            <div class="content-page">
                <div class="block_password_changed">
                    <h2>
                        Congratulations!
                    </h2>
                    <div class="description">
                        You’ve successfully changed your old password! Please log in using your new password.
                    </div>
                    <router-link :to="{name: 'login'}" class="right">Log in</router-link>
                </div>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
import confetti from '@/mixins/confetti.js';
export default {
    name: 'password-changed',
    components: {
        lassoLayout
    },
	mixins: [confetti]
}
</script>
